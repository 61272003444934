<template>
  <div class="main">
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-toolbar flat>
          <v-btn
            style="float: left"
            color="pink"
            fab
            text
            @click="closeCRUDDialog"
          >
            <v-icon medium>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ ' Report' }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout>
              <v-flex xs12>
                <v-text-field
                  label="Name of the Report"
                  outlined
                  v-model="config.displayName"
                  placeholder="e.g. Monthly Sales Report"
                ></v-text-field>
                <v-textarea
                  label="Creator Note"
                  v-model="config.comments"
                  auto-grow
                  outlined
                  rows="3"
                  row-height="25"
                ></v-textarea>
                <v-btn color="primary" @click="saveReport" class="mr-2"
                  >Save</v-btn
                >
                <v-btn text @click="clearFields">Clear</v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const initialState = () => ({
  config: {
    displayName: '',
    comments: ''
  }
})
export default {
  props: ['createReportDialog'],
  data() {
    return {
      dialog: false,
      ...initialState()
    }
  },
  created() {
    if (this.createReportDialog) this.dialog = true
  },
  methods: {
    closeCRUDDialog() {
      this.clearFields()
      this.dialog = false
      this.$emit('closeCreateDialog')
    },
    async saveReport() {
      console.log('0', this.config)
      this.$store.commit('toggleLoader')
      let payload = {
        displayName: this.config.displayName.trim(),
        name: this.config.displayName
          .trim()
          .toLowerCase()
          .replace(RegExp(' ', 'g'), '_'),
        comments: this.config.comments.trim()
      }
      let res = await this.$store.dispatch('createReport', payload)
      console.log('[res]: ', res)
      this.$store.commit('toggleLoader')
      this.$router.push('/compose-report')
      this.closeCRUDDialog()
    },
    clearFields() {
      this.config = initialState()
    }
  }
}
</script>